<template>
  <v-dialog v-model="showDialogParam" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-form data-vv-scope="form-elm-add">
          <v-text-field
            outlined
            :label="field"
            v-model="name"
            v-validate="rules"
            :error-messages="errors.collect(`form-elm-add.${field}`)"
            :data-vv-name="field"
            data-vv-validate-on="change"
            data-testid="single-text"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="$emit('update:dialog', false)"
          data-testid="btn-single-text-cancel"
        >
          Cancel
        </v-btn>
        <v-btn
          color="success"
          text
          @click="save()"
          data-testid="btn-single-text-save"
        >
          {{ actionButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Component that is dialog with possibility to add single text field with simple validation
 */

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    field: {
      type: String,
      default: "",
    },
    rules: {
      type: [String],
      default: "required",
    },
    actionButtonText: {
      type: String,
      default: "Save",
    },
  },

  data() {
    return {
      name: "",
    }
  },

  watch: {
    showDialogParam: function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.$validator.errors.clear("form-elm-add")
        this.name = ""
      }
    },
  },

  methods: {
    save() {
      let app = this
      app.$validator.validateAll("form-elm-add").then((result) => {
        if (result) {
          app.$emit("update:dialog", false)
          app.$emit("save-field", app.name)
        }
      })
    },
  },

  computed: {
    showDialogParam: {
      get() {
        return this.dialog
      },
      set(val) {
        this.$emit("update:dialog", val)
      },
    },
  },
}
</script>
