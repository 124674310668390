<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-form
      data-vv-scope="form-signal-servers"
      data-testid="form-signal-servers"
    >
      <v-card>
        <v-card-title>
          <span class="headline" v-html="getTitle()"></span>
          <v-spacer />
          <v-switch
            v-model="activeItem.is_default"
            label="Default"
            input-value="false"
            data-vv-validate-on="change"
            class="mx-4"
            data-testid="switch-signal-is-default"
          ></v-switch>
          <v-switch
            v-model="activeItem.is_active"
            label="Active"
            input-value="true"
            data-vv-validate-on="change"
            data-testid="switch-signal-is-active"
          ></v-switch>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="3">
                <v-select
                  outlined
                  v-model="activeItem.protocol"
                  :items="protocols_list"
                  item-text="name"
                  item-value="pk"
                  :menu-props="{ maxHeight: '400' }"
                  label="Protocol"
                  hint="Url protocol"
                  persistent-hint
                  :error-messages="
                    errors.collect('form-signal-servers.protocol')
                  "
                  data-vv-name="protocol"
                  v-validate="'required'"
                  data-vv-validate-on="change"
                  data-testid="protocol"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  label="Hostname"
                  v-model="activeItem.hostname"
                  :error-messages="
                    errors.collect('form-signal-servers.hostname')
                  "
                  data-vv-name="hostname"
                  data-vv-validate-on="change"
                  v-validate="{
                    required: true,
                    regex: /^((?!-)[A-Za-z0-9-]{1,63}(?!-)\.)+[A-Za-z]{2,6}$/,
                  }"
                  data-testid="hostname"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  label="Port"
                  v-model="activeItem.port"
                  :error-messages="errors.collect('form-signal-servers.port')"
                  data-vv-name="port"
                  data-vv-validate-on="change"
                  type="number"
                  v-validate="{ required: true, max: 5, min_value: 1 }"
                  :min="1"
                  data-testid="port"
                />
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  outlined
                  label="Name"
                  v-model="activeItem.name"
                  :error-messages="errors.collect('form-signal-servers.name')"
                  v-validate="{
                    required: true,
                    max: 25,
                    regex: /^[^~$%^{}<>]*$/,
                  }"
                  data-vv-name="name"
                  data-vv-validate-on="change"
                  data-testid="name"
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  outlined
                  v-model="activeItem.balancing_group"
                  :items="balancing_groups_list"
                  :menu-props="{ maxHeight: '400' }"
                  label="Balancing group"
                  :error-messages="
                    errors.collect('form-signal-servers.balancing_group')
                  "
                  data-vv-name="balancing_group"
                  v-validate="'required'"
                  data-vv-validate-on="change"
                  append-outer-icon="mdi-plus"
                  @click:append-outer="dialogBalancingGroup = true"
                  data-testid="balancing-group"
                />
              </v-col>
              <v-col cols="12" sm="12">
                <SelectSignalsAsync
                  v-model="activeItem.backup"
                  outlined
                  label="Backup"
                  :multiple="false"
                  item-text="data.name"
                  item-value="_key"
                  :error-messages="errors.collect(`form-signal-servers.backup`)"
                  data-vv-name="backup"
                  data-vv-validate-on="change"
                  data-vv-as="Backup"
                  field="backup"
                  :menu-props="{ maxHeight: 304 }"
                  :relist="dialog"
                  data-testid="signal-backup"
                />
              </v-col>
              <v-col cols="12" sm="12">
                <v-combobox
                  v-model="activeItem.proxies"
                  :items="proxiesList"
                  chips
                  outlined
                  label="Proxy list"
                  multiple
                  append-outer-icon="mdi-plus"
                  @click:append-outer="dialogAddProxyServer = true"
                  append-icon="false"
                  readonly
                  data-testid="proxy-list"
                >
                  <template
                    v-slot:selection="{ attrs, item, select, selected }"
                  >
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      @click="select"
                      @click:close="removeProxy(item)"
                    >
                      <strong>{{ item }}</strong>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click.native="closeDialog()"
            data-testid="btn-close"
          >
            Close
          </v-btn>
          <v-btn
            color="success"
            text
            @click.stop="save()"
            data-testid="btn-save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-snackbar
      v-model="snackbar.show"
      :bottom="snackbar.y === 'bottom'"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <v-btn color="pink" text @click="snackbar.show = false"> Close </v-btn>
    </v-snackbar>

    <BalancingGroupDialog
      :dialog.sync="dialogBalancingGroup"
      @save-balancing-group="saveBalancingGroup"
    ></BalancingGroupDialog>

    <AddSingleTextDialog
      :dialog.sync="dialogAddProxyServer"
      @save-field="addNewProxy"
      title="Add proxy server to signal"
      field="Proxy"
      rules="required|url"
    ></AddSingleTextDialog>
  </v-dialog>
</template>

<script>
import _ from "lodash"
import BalancingGroupDialog from "../BalancingGroupDialog"
import AddSingleTextDialog from "@/components/commonComponents/AddSingleTextDialog"
import SelectSignalsAsync from "@/components/selects/SelectSignalsAsync.vue"

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    activeItem: {
      type: Object,
      default() {
        return {}
      },
    },
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
  },
  components: {
    BalancingGroupDialog,
    AddSingleTextDialog,
    SelectSignalsAsync,
  },
  data() {
    return {
      protocols_list: [
        { pk: "https", name: "https" },
        { pk: "http", name: "http" },
      ],
      snackbar: {
        show: false,
        y: "top",
        x: null,
        mode: "",
        timeout: 3000,
        text: "Profile succesfully saved.",
        color: "success",
      },
      balancing_groups_list: [],
      dialogBalancingGroup: false,
      dialogAddProxyServer: false,
      proxiesList: [],
    }
  },
  watch: {
    dialog: function (newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.getBalancingGroupList()
      }
    },
  },
  methods: {
    addNewProxy(item) {
      if (!Array.isArray(this.activeItem.proxies)) {
        this.activeItem.proxies = []
      }
      this.activeItem.proxies.push(item)
    },

    removeProxy(item) {
      this.activeItem.proxies.splice(this.activeItem.proxies.indexOf(item), 1)
      this.activeItem.proxies = [...this.activeItem.proxies]
    },

    getTitle() {
      return this.editedIndex == -1
        ? "Add new signal server"
        : `<span class="blue-grey--text text--lighten-4">Edit signal server</span> ${this.activeItem.name}`
    },

    createSignalServer(data) {
      let self = this
      self.$validator.errors.clear("form-signal-servers")
      self.$validator.validateAll("form-signal-servers").then((result) => {
        if (result) {
          self.$store
            .dispatch("signalServersCreateAction", data)
            .then(({ data }) => {
              self.$emit("signal-server-data-saved")
              self.closeDialog()
            })
            .catch(({ response }) => {
              console.log("eeerrrrorrr", response)
              self.handleValidationErrors(response)
            })
        }
      })
    },

    editSignalServer(data) {
      let self = this
      self.$validator.errors.clear("form-signal-servers")
      self.$validator.validateAll("form-signal-servers").then((result) => {
        if (result) {
          self.$store
            .dispatch("signalServersEditAction", data)
            .then(({ data }) => {
              self.$emit("signal-server-data-saved")
              self.closeDialog()
            })
            .catch(({ response }) => {
              //self.handleValidationErrors(response)
              console.log("error on editSignalServer")
            })
        }
      })
    },

    save() {
      let self = this
      let formData = {
        data: {
          type: "signal_server",
          data: {
            name: self.activeItem.name,
            protocol: self.activeItem.protocol,
            hostname: self.activeItem.hostname,
            port: self.activeItem.port,
            is_active: self.activeItem.is_active,
            real_ip: self.activeItem.real_ip,
            balancing_group: self.activeItem.balancing_group,
            backup: self.activeItem.backup,
            proxies: self.activeItem.proxies,
            is_default: self.activeItem.is_default,
          },
        },
      }

      if (this.editedIndex > -1) {
        formData["slug"] = this.editedIndex
        self.editSignalServer(formData)
      } else {
        self.createSignalServer(formData)
      }
    },

    deleteSignalServer(item) {
      let self = this
      self.$store.dispatch("saveTableLoaderAction", true)
      self.$store
        .dispatch("signalServersDeleteAction", item)
        .then(({ data }) => {
          self.$emit("signal-server-data-saved")
          self.$store.dispatch("saveTableLoaderAction", false)
        })
    },

    closeDialog() {
      let self = this
      this.$emit("update:dialog", false)
      self.$validator.errors.clear("form-signal-servers")
    },

    toggleAll() {
      if (this.selectedEngines.length) this.selectedEngines = []
      else this.selectedEngines = this.engines.slice()
    },

    handleValidationErrors(response) {
      let self = this
      _.forEach(response.data, function (val, key) {
        self.$validator.errors.add({
          scope: "form-signal-servers",
          field: key,
          msg: val,
        })
      })
    },

    getBalancingGroupList() {
      let self = this
      self.$store
        .dispatch("balancingGroupsGetListAction")
        .then(({ data }) => {
          self.balancing_groups_list = data.data.balancing_groups
        })
        .catch(({ response }) => {
          self.handleValidationErrors(response)
        })
    },

    saveBalancingGroup(name) {
      this.balancing_groups_list.push(name)
      this.activeItem.balancing_group = name
    },
  },
}
</script>
